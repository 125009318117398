import { render, staticRenderFns } from "./LoginMenu.vue?vue&type=template&id=a58befd8&scoped=true&"
import script from "./LoginMenu.vue?vue&type=script&lang=ts&"
export * from "./LoginMenu.vue?vue&type=script&lang=ts&"
import style0 from "./LoginMenu.vue?vue&type=style&index=0&id=a58befd8&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a58befd8",
  null
  
)

export default component.exports