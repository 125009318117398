
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "LoginMenu",
  components: {},
})
/** @group Authentication */
export default class LoginMenu extends Vue {
  // Log the user out
  public logout() {
    this.$auth.logout({
      logoutParams: { returnTo: window.location.origin },
    });
  }
}
