import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#7aab8a", // lighter green
        secondary: "#669580", // darker green
        accent: colors.shades.black,
        error: colors.red.accent3,
      },
      dark: {
        primary: "#5c9e1d", // lighter green
        secondary: "#15470f", // darker green
      },
    },
  },
});
