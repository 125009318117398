/**  User.ts is a class to make the type definition of the User in VueAuth.ts nicer. */
export class User {
  public sub: string | undefined;
  public names: string | undefined;
  public nickname: string | undefined;
  public picture: string | undefined;
  public updatedAt: string | undefined;
  public email: string | undefined;
  public emailVerified: boolean | undefined;

  public provider?: string;
  public id?: string;

  public givenName?: string;
  public familyName?: string;
  public locale?: string;
  [key: string]: string | boolean | undefined;

  constructor(auth0User: { [key: string]: string | boolean | undefined }) {
    if (!auth0User) {
      return;
    }
    for (const key of Object.keys(auth0User)) {
      this[key] = auth0User[key];
    }
    /**
     * Sub is the OpenID ID Token's Subject Identifier, which contains
     * the authentication provider (eg. auth0 or google) and the actual user id,
     * separated by a |. Provider and id are a result of splitting sub.
     * Example: github|73471037 is sub, github is provider and that number is my id.
     */
    this.sub = auth0User.sub as string;
    this.provider = this.sub.split("|")[0];
    this.id = this.sub.split("|")[1];
  }
}
