
import Vue from "vue";

import { Component } from "vue-property-decorator";
// import LoadingDialog from "./components/LoadingDialog.vue";
import LoginMenu from "./LoginMenu.vue";

@Component({
  name: "Navbar",
  components: { LoginMenu },
  props: [],
})
export default class Navbar extends Vue {
  public loading = false;
  public showProgressBar() {
    this.loading = true;
    setTimeout(() => (this.loading = false), 2000);
  }

  public login() {
    this.$auth.loginWithRedirect({});
  }
}
