import { getInstance } from "./auth";
import { NavigationGuard } from "vue-router";

export const authenticationGuard: NavigationGuard = (to, from, next) => {
  const authService = getInstance();
  // Watch for the loading property to change before we check isAuthenticated
  const unwatch = authService.$watch("loading", (loading: boolean) => {
    if (loading === false) {
      return fn();
    }
  });
  const fn = () => {
    // Unwatch loading
    unwatch && unwatch();

    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }
    // Otherwise, log in
    authService.loginWithRedirect({});
    return next("/");
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }
};
